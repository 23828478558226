import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import './Nav.scss';
import { INav } from '../../../types/types';

const Nav: FC<INav> = (props) => {
    const navLinks = props.links;

    return (
        <ul className={`nav nav--${props.type}`}>
            <div className={`nav__container nav__container--${props.typeContainer}`}>
                {navLinks.links.map((item, index) => (
                    <Link
                        data-testid="nav"
                        activeClassName="active__border--style"
                        to={`${item.page}`}
                        className={`link__margin  active__border nav__link ${props.linkType}`}
                        state={{ choice: props.path }}
                        ref={props.linkRef}
                        key={index}
                    >
                        {item.title}
                    </Link>
                ))}
            </div>
        </ul>
    );
};

export default Nav;

import React, { FC } from 'react';
import SectionTitle from '../../../common/SectionTitle';
import TextDescription from '../../../common/TextDescription';
import { IBlogHEaderProps, TMenuLinks } from '../../../../types/types';

import './BlogHeader.scss';
import Nav from '../../../common/Nav';

const BlogHeader: FC<IBlogHEaderProps> = (props) => {
    const menuLinks: TMenuLinks = {
        activeLink: null,
        links: [
            { id: 1, title: 'All', page: '/blog/' },
            { id: 2, title: 'Studies', page: '/blog/studies/' },
            { id: 3, title: 'Posts', page: '/blog/posts/' },
        ],
    };
    return (
        <div data-testid="blog-header" className="blog-header">
            <div className="blog-header__wrapper">
                <SectionTitle type="section" title={props.blogTitle} />
                <div className="blog-header__subtitle">
                    <TextDescription>{props.blogSubtitle}</TextDescription>
                </div>
            </div>
            <Nav type="blog" links={menuLinks} linkType="link__blog" />
        </div>
    );
};

export default BlogHeader;

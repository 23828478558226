import React, { FC } from 'react';
import '../Blog.scss';
import LargeCompanyContainer from '../../../components/pagesComponents/MainPage/LargeCompanyContainer';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { Link } from 'gatsby';
import BlogLayout from '../../../components/PagesLayouts/BlogLayouts';

const blogPosts: FC = () => {
    // useScrollToTop();
    return (
        <BlogLayout>
            {/*<div className="blog__cards">*/}
            <div className="blog__cards-wrapper">
                <Link to="/blog/unlocking-the-power-of-managed-service/">
                    <div className="blog__card">
                        <LargeCompanyContainer
                            // readtime="3 min read"
                            color="dark"
                            // date="August 30, 2023"
                            logoPos="hide"
                            author="Yurii Drozd"
                            typeBG="solid-bg"
                            description="Unlocking the Power of Managed Services: A Smart Choice for Scaling Engineering Team"
                            label="CEO"
                            labelType="editorial"
                            hashtags="Managed Service, Agile, Engagement Models"
                            size="blog"
                        />
                    </div>
                </Link>
                <Link to="/blog/co-innovation-in-action/">
                    <div className="blog__card">
                        <LargeCompanyContainer
                            // readtime="3 min read"
                            color="dark"
                            // date="July 28, 2023"
                            author="Yurii Drozd"
                            logoPos="hide"
                            typeBG="solid-bg"
                            description="Co-Innovation in Action: Achieving Remarkable Results"
                            label="CEO"
                            labelType="editorial"
                            hashtags="Project Management, Agile, Co-innovation, Product Ownership, Engagement Models"
                            size="blog"
                        />
                    </div>
                </Link>
            </div>
            <div className="blog__cards-wrapper">
                <Link to="/blog/transforming-industries-with-web-3/">
                    <div className="blog__card">
                        <LargeCompanyContainer
                            // readtime="5 min read"
                            color="dark"
                            // date="May 23, 2023"
                            author="Yurii Drozd"
                            logoPos="hide"
                            typeBG="solid-bg"
                            description="Transforming Industries with Web 3.0: What You Need to Know"
                            label="CEO"
                            labelType="editorial"
                            hashtags="Blockchain, Web3, Defi, Software Development"
                            size="blog"
                        />
                    </div>
                </Link>
                <Link to="/blog/say-goodbye-to-third-party-cookies/">
                    <div className="blog__card">
                        <LargeCompanyContainer
                            // readtime="3 min read"
                            color="dark"
                            // date="May 31, 2023"
                            author="Yurii Drozd"
                            logoPos="hide"
                            typeBG="solid-bg"
                            description="Say Goodbye to Third-Party Cookies: What Google’s Move Means for Digital Advertising"
                            label="CEO"
                            labelType="editorial"
                            hashtags="Digital Marketing, Software Development, Adtech"
                            size="blog"
                        />
                    </div>
                </Link>
            </div>
            <div className="blog__cards-wrapper">
                <Link to="/blog/building-a-remote-development-team/">
                    <div className="blog__card">
                        <LargeCompanyContainer
                            // readtime="3 min read"
                            color="dark"
                            // date="May 15, 2023"
                            author="Yurii Drozd"
                            logoPos="hide"
                            typeBG="solid-bg"
                            description="Building a Remote Development Team: Best Practices for Managing Distributed Software Engineering"
                            label="CEO"
                            labelType="editorial"
                            hashtags="Software Engineering, Agile, Remote Working, Software Development"
                            size="blog"
                        />
                    </div>
                </Link>
            </div>
        </BlogLayout>
    );
};

export default blogPosts;

import React, { FC, Fragment } from 'react';
import SEO from '../../SEO';
import BlogHeader from '../../pagesComponents/Blog/BlogHeader';
import FixedButton from '../../common/FixedButton';
import Layout from '../../layout';

const BlogLayout: FC = (props) => {
    return (
        <Fragment>
            <Layout>
                <SEO
                    pageTitle="Blackbird Lab - Blog"
                    pageDescription="Articles, stories, case studies technical guides, best practices and lots of resources to help you build, grow, and skyrocket your product..."
                    pageImage="https://blackbird-image-cdn.s3.eu-central-1.amazonaws.com/blog/blog.png"
                />
                <section className="blog">
                    <BlogHeader
                        blogTitle="Blog"
                        blogSubtitle=" Articles, stories, case studies technical guides, best practices, tech videos,
                    and lots of resources to help you build, grow, and skyrocket your product or
                    digital side of your business."
                    />
                    <div className="blog__cards">
                        {props.children}
                        {/*<div className="blog__cards-wrapper">*/}
                        {/*    <Link to="/blog/unlocking-the-power-of-managed-service/">*/}
                        {/*        <div className="blog__card">*/}
                        {/*            <LargeCompanyContainer*/}
                        {/*                readtime="3 min read"*/}
                        {/*                color="dark"*/}
                        {/*                date="August 30, 2023"*/}
                        {/*                logoPos="hide"*/}
                        {/*                author="Yurii Drozd"*/}
                        {/*                typeBG="solid-bg"*/}
                        {/*                description="Unlocking the Power of Managed Services: A Smart Choice for Scaling Engineering Team"*/}
                        {/*                label="CEO"*/}
                        {/*                labelType="editorial"*/}
                        {/*                hashtags="Managed Service, Agile, Engagement Models"*/}
                        {/*                size="blog"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </Link>*/}
                        {/*    <Link to="/blog/co-innovation-in-action/">*/}
                        {/*        <div className="blog__card">*/}
                        {/*            <LargeCompanyContainer*/}
                        {/*                readtime="3 min read"*/}
                        {/*                color="dark"*/}
                        {/*                date="July 28, 2023"*/}
                        {/*                author="Yurii Drozd"*/}
                        {/*                logoPos="hide"*/}
                        {/*                typeBG="solid-bg"*/}
                        {/*                description="Co-Innovation in Action: Achieving Remarkable Results"*/}
                        {/*                label="CEO"*/}
                        {/*                labelType="editorial"*/}
                        {/*                hashtags="Project Management, Agile, Co-innovation, Product Ownership, Engagement Models"*/}
                        {/*                size="blog"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                        {/*<div className="blog__cards-wrapper">*/}
                        {/*    <Link to="/blog/transforming-industries-with-web-3/">*/}
                        {/*        <div className="blog__card">*/}
                        {/*            <LargeCompanyContainer*/}
                        {/*                readtime="5 min read"*/}
                        {/*                color="dark"*/}
                        {/*                date="May 23, 2023"*/}
                        {/*                author="Yurii Drozd"*/}
                        {/*                logoPos="hide"*/}
                        {/*                typeBG="solid-bg"*/}
                        {/*                description="Transforming Industries with Web 3.0: What You Need to Know"*/}
                        {/*                label="CEO"*/}
                        {/*                labelType="editorial"*/}
                        {/*                hashtags="Blockchain, Web3, Defi, Software Development"*/}
                        {/*                size="blog"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </Link>*/}
                        {/*    <Link to="/blog/say-goodbye-to-third-party-cookies/">*/}
                        {/*        <div className="blog__card">*/}
                        {/*            <LargeCompanyContainer*/}
                        {/*                readtime="3 min read"*/}
                        {/*                color="dark"*/}
                        {/*                date="May 31, 2023"*/}
                        {/*                author="Yurii Drozd"*/}
                        {/*                logoPos="hide"*/}
                        {/*                typeBG="solid-bg"*/}
                        {/*                description="Say Goodbye to Third-Party Cookies: What Google’s Move Means for Digital Advertising"*/}
                        {/*                label="CEO"*/}
                        {/*                labelType="editorial"*/}
                        {/*                hashtags="Digital Marketing, Software Development, Adtech"*/}
                        {/*                size="blog"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                        {/*<div className="blog__cards-wrapper">*/}
                        {/*    <Link to="/blog/building-a-remote-development-team/">*/}
                        {/*        <div className="blog__card">*/}
                        {/*            <LargeCompanyContainer*/}
                        {/*                readtime="3 min read"*/}
                        {/*                color="dark"*/}
                        {/*                date="May 15, 2023"*/}
                        {/*                author="Yurii Drozd"*/}
                        {/*                logoPos="hide"*/}
                        {/*                typeBG="solid-bg"*/}
                        {/*                description="Building a Remote Development Team: Best Practices for Managing Distributed Software Engineering"*/}
                        {/*                label="CEO"*/}
                        {/*                labelType="editorial"*/}
                        {/*                hashtags="Software Engineering, Agile, Remote Working, Software Development"*/}
                        {/*                size="blog"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                    </div>
                </section>
                <FixedButton />
            </Layout>
        </Fragment>
    );
};

export default BlogLayout;
